.Form {
  width: 100%;
  height: 100%;
}

.Form .tabs {
  display: flex;
  height: 60%;
}

.Form .tabs button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  flex: 1;
  padding-top: 3px;
  transition: background-color 150ms ease-out;
}

.Form .tabs button:hover {
  background-color: var(--content-light);
}

.Form .tabs button.active {
  padding-top: 0px;
  background-color: var(--content-light);
  border-top: 3px solid var(--post-plant-red);
}

.Form .tabs h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 40px;
}

.Form .tabs h2 {
  font-size: 2rem;
  margin-bottom: 30px;
}

.Form .tabs p {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-light);
  text-align: center;
}

.Form .tab-graph {
  height: 40%;
  width: 100%;
}
