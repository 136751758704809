.Maps .PageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.PageContent .MapItemLink {
  text-decoration: none;
}

.Maps .PageContent .MapItemLink:last-child {
  margin-bottom: 30px;
}

.Maps .PageContent .MapsItem .ContentBox {
  transition: all 100ms ease-out;
}

.Maps .PageContent .MapsItem .ContentBox:hover {
  background-color: var(--content-light);
  transform: scale(1.05);
}
