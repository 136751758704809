.TOS {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.TOS-Container {
  width: 60%;
  padding: 2rem 0px;
}

.TOS-Container p {
  line-height: 25px;
}

.TOS li {
  margin-left: 40px;
}

.TOS h1 {
  font-weight: 600;
  padding-bottom: 0.25rem;
}

.TOS h2 {
  font-size: 2.5rem;
}

.TOS h4 {
  color: var(--text-light);
  font-weight: 400;
}
