.OpeningDuels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;

  width: 100%;
  height: 100%;
}

.OpeningDuels .OpeningStat {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.OpeningDuels .OpeningStat .OpeningWord {
  width: 250px;
}

.OpeningDuels .OpeningStat p {
  font-family: var(--body-font);
}

.OpeningDuels .OpeningStat h2 {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
