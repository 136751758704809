@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Poppins&family=Montserrat:wght@400;600;800&display=swap");

:root {
  --content: #303136;
  --content-light: #404146;
  /* --content-outline: #2f3653; */

  --background: #27282c;
  --background-dark: #17181a;
  /* --background-light: #1b1f31; */
  /* --background-light-select: #1f2439; */

  --post-plant-red: #d30011;

  --stats-red: #ff4f4f;
  --stats-yellow: #ffc454;
  --stats-green: #30c464;
  /* Or #279D51 */

  --valorant-red: #ff4655;
  --valorant-blue: #22ffc5;
  --valorant-yellow: #f0cb74;

  --text: #ffffff;
  --text-secondary: #737375;
  --text-light: #858585;

  --title-font: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --body-font: "Manrope", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;

  --content-box-radius: 20px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--title-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  -webkit-overflow-scrolling: auto;
  overflow-x: hidden;
}

code {
  font-family: var(--body-font);
}

a {
  text-decoration: none;
  color: white;
}
