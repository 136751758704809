.VODStartTime {
  width: 1100px;
  min-width: 1100px;
  height: 600px;
  background-color: var(--content);
  border: 1px solid var(--content-light);
  border-radius: 20px;
  overflow: hidden;

  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.08),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.08), 0px 6px 6px -3px rgba(0, 0, 0, 0.08),
    0px 12px 12px -6px rgba(0, 0, 0, 0.08),
    0px 24px 24px -12px rgba(0, 0, 0, 0.08);

  display: flex;
  text-align: center;
}

.VODStartTime .StartTimeLeftSide {
  display: flex;
  flex-direction: column;
}

.VODStartTime .StartTimeLeftSide .VideoUnderneathInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.VODStartTime .StartTimeButtons {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.VODStartTime .StartTimeRightSide {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  padding-left: 20px;
  padding-right: 20px;
}

.VODStartTime .StartTimeRightSide .StartTimeRightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 5px;
}

.VODStartTime .StartTimeRightSide h1 {
  font-size: 2rem;
}

.VODStartTime .SetStartTime button {
  background-color: var(--post-plant-red);
  color: white;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 1rem;

  width: 200px;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: 1px solid var(--stats-red);

  text-align: center;
  white-space: nowrap;

  position: relative;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: background-color 100ms ease-out;
}

.VODStartTime .SetStartTime button:hover {
  background-color: var(--stats-red);
}

.VODStartTime .GoBack button {
  background-color: var(--background);
  color: white;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 1rem;

  width: 300px;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;

  text-align: center;
  white-space: nowrap;

  position: relative;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--content-light);

  transition: background-color 100ms ease-out;
}

.VODStartTime .GoBack button:hover {
  background-color: var(--content-light);
}
