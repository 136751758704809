.App {
  height: 100vh;
  width: 100vw;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */

  background-color: var(--background);
  color: var(--text);
}
