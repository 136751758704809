.MapOverview {
  background-color: var(--content);
  border-radius: var(--content-box-radius);
  border: 1px solid var(--content-light);

  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.08),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.08), 0px 6px 6px -3px rgba(0, 0, 0, 0.08),
    0px 12px 12px -6px rgba(0, 0, 0, 0.08),
    0px 24px 24px -12px rgba(0, 0, 0, 0.08);

  padding: 15px;
  overflow: hidden;
  width: 400px;
  height: 360px;
}

.MapsItemContent {
  display: flex;
  flex-direction: column;
}

.MapsItemContent .Heading {
  display: flex;
  position: relative;
  margin-top: -15px;
  margin-left: -20px;
  top: 0;
  min-height: 100px;
}

.MapsItemContent .Heading h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: var(--text);
  font-size: 2.5rem;
}

.MapsItemContent .Heading img {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.MapsItemContent .MainContentStats {
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--text);
  font-size: 1.5rem;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  gap: 30px;
}

.MapsItemContent .MainContentStats .ContentSection {
  display: flex;
  justify-content: space-around;
}

.MapsItemContent .MainContentStats .ContentSection .Winrate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MapsItemContent .MainContentStats .ContentSection > div {
  width: 100%;
  text-align: center;
}
