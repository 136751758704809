.Replay {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.Replay .Content {
  display: flex;
  flex-direction: row;
}
