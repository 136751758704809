.AgentsContent {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  height: 100%;

  list-style: none;
  white-space: nowrap;
}

.AgentsContent .AgentsLegend {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AgentsContent .AgentsLegend .LegendKey {
  margin-right: auto;
  margin-left: 15px;
}

.AgentsContent .AgentsLegend .LegendKey:first-child {
  margin-left: 5px;
  margin-right: 20px;
}

.AgentsContent .AgentsLegend .LegendKey:nth-child(2) {
  margin-right: 50px;
}

.AgentsContent li:nth-child(odd) > .AgentItem {
  background-color: var(--content-light);
}
