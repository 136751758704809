.AgentPreview {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 40px;
  border-radius: 15px;
  background-color: var(--content);

  overflow: hidden;
  color: white;

  font-size: 1rem;

  transition: background-color 100ms ease-out;
  cursor: default;
}

.AgentPreview .Agent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
}

.AgentPreview .AgentItemContainer:not(:first-child) {
  margin-right: auto;
  margin-left: 20px;
}

.AgentPreview .AgentItemContainer:nth-child(2) {
  margin-left: 10px;
  width: 30px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(3) {
  margin-left: 40px;
  width: 70px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(4) {
  margin-left: 0px;
  width: 60px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(5) {
  margin-left: 10px;
  width: 60px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(6) {
  margin-left: 20px;
  width: 60px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(7) {
  margin-left: 15px;
  width: 70px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(8) {
  margin-left: -5px;
  width: 70px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(9) {
  margin-left: 0px;
  width: 70px;
  text-align: center;
}

.AgentPreview .AgentItemContainer:nth-child(10) {
  margin-left: 0px;
  width: 70px;
  text-align: center;
}
