.LogoContainer .Logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 1.2rem;
}

.LogoContainer {
  width: 100%;
  padding: 20px;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: var(--text);
  font-family: var(--title-font);
  font-weight: 600;
}

.LogoContainer:visited {
  color: var(--text);
}
