.LoginLoading {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 200px;
  align-items: center;
}

.LoginLoading .LogingLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 200px;

  background-color: var(--content);
  border-radius: var(--content-box-radius);
  border: 1px solid var(--content-light);

  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.08),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.08), 0px 6px 6px -3px rgba(0, 0, 0, 0.08),
    0px 12px 12px -6px rgba(0, 0, 0, 0.08),
    0px 24px 24px -12px rgba(0, 0, 0, 0.08);
}

.LoginLoading .Logo {
  margin-top: -40px;
}
