.VODItem {
  /* YouTube color */
  border: 3px solid rgb(234, 51, 35);
  background-color: var(--content);
  width: 250px;
  height: 40px;

  display: flex;
  justify-content: space-between;

  align-items: center;
  overflow: hidden;

  border-radius: 20px;
  cursor: pointer;
  transition: transform 200ms ease-out;
}
.VODItem:hover {
  transform: scale(1.05);
}

.VODItem .VODItemIcon {
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  background-color: rgb(234, 51, 35);
}

.VODItem .VODItemEdit {
  height: 100%;
  padding-right: 8px;
  padding-left: 10px;

  display: flex;
  align-items: center;
  transition: background-color 100ms ease-out;
}

.VODItem .VODItemEdit:hover {
  background-color: var(--content-light);
}
