.CallButton button {
  background-color: var(--post-plant-red);
  color: white;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 1.5rem;

  width: 220px;
  padding: 10px 30px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;

  text-align: center;
  white-space: nowrap;

  position: relative;
  z-index: 3;

  transition: background-color 100ms ease-out;
}

.CallButton button:hover {
  background-color: var(--stats-red);
}
