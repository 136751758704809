.Winrate {
  height: 95%;
  width: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Winrate .WinrateCircle {
  width: 280px;
  height: 280px;

  border-radius: 50%;
}

.Winrate .WinrateCircle .WinrateText {
  font-size: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
