.ScoreboardTeam {
  overflow-x: hidden;
}

.ScoreboardTeam .Details {
  display: flex;
  gap: 35px;
  font-size: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  white-space: nowrap;
}

.ScoreboardTeam .Players {
  display: flex;
  flex-direction: column;
}

.ScoreboardTeam .Players img {
  margin-top: 5px;
}

.ScoreboardTeam .Detail:nth-child(2) {
  margin-left: 120px;
}

.ScoreboardTeam .Detail:nth-child(3) {
  margin-left: 2px;
}

.ScoreboardTeam .Detail:nth-child(4) {
  margin-left: 13px;
}

.ScoreboardTeam .Detail:nth-child(5) {
  margin-left: 1px;
}

.ScoreboardTeam .Detail:nth-child(6) {
  margin-left: -1px;
}

.ScoreboardTeam .Detail:nth-child(7) {
  margin-left: 12px;
}
.ScoreboardTeam .Detail:nth-child(8) {
  margin-left: 10px;
}
.ScoreboardTeam .Detail:nth-child(9) {
  margin-left: 14px;
}
.ScoreboardTeam .Detail:nth-child(10) {
  margin-left: 11px;
}
.ScoreboardTeam .Detail:nth-child(11) {
  margin-left: 6px;
}
.ScoreboardTeam .Detail:nth-child(12) {
  margin-left: 10px;
}

.ScoreboardTeam .Detail:nth-child(13) {
  margin-left: 12px;
}

.ScoreboardTeam .Detail:nth-child(14) {
  margin-left: 10px;
}

.ScoreboardTeam .Detail:nth-child(15) {
  margin-left: 8px;
}

.ScoreboardTeam .Detail:nth-child(16) {
  margin-left: 0px;
  margin-right: 15px;
}
