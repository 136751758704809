.SidebarItem .LinkContainer {
  padding-left: 60px;

  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 15px;

  text-decoration: none;
  color: var(--text-light);
  font-family: var(--title-font);
  font-weight: 400;

  transition: color 100ms ease-out;
}

.SidebarItem h5 {
  margin-top: -5px;
  font-weight: 400;
}

.SidebarItem .LinkContainer:visited {
  color: var(--text-light);
}

.SidebarItem .LinkContainer:hover {
  color: var(--text);
}

.SidebarItem a.active .SidebarIcon {
  color: var(--text);
}

.SidebarItem a.active h5 {
  color: var(--text);
}

.SidebarItem a.active .SelectBox {
  visibility: visible;
}

.SidebarItem .SelectBox {
  position: absolute;
  right: 0;
  height: 40px;
  width: 5px;

  border-radius: 15px 0px 0px 15px;
  background-color: var(--post-plant-red);

  visibility: hidden;
}
