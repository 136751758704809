.AddVOD {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 150px;
}
.AddVOD button {
  background-color: var(--post-plant-red);
  color: white;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 1rem;

  width: 200px;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;

  text-align: center;
  white-space: nowrap;

  position: relative;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition: background-color 100ms ease-out;
}

.AddVOD button:hover {
  background-color: var(--stats-red);
}

.AddVOD input[type="text"] {
  padding: 10px;
  margin: 10px 0;
  width: 300px;
  color: white;
  border-radius: 5px;
  background-color: var(--content-light);

  border: 1px solid var(--background);
  transition: border 100ms ease-out;
}

.AddVOD input[type="text"]:hover {
  border: 1px solid white;
}

.AddVOD input[type="text"]:focus {
  border: 1px solid white;
}
