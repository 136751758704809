.ProgressBar {
  width: 100%;
  height: 40px;
  position: relative;
}

.ProgressBar h4 {
  position: absolute;
  top: 0;
  right: 0;
}
