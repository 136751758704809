.Frontpage {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: var(--background-dark);
  overflow: hidden;
}

.Frontpage .ImageContainer {
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.Frontpage .ImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 10%,
    transparent 100%
  );
}

.Frontpage .FrontpageHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 200px;
  text-align: center;
}
.Frontpage .FrontpageHeading .CallButton {
  margin-top: 50px;
}

.Frontpage .FrontpageHeading .MouseScrollDown {
  width: 50px;
  height: 90px;
  border: 3px solid white;
  border-radius: 60px;
  position: relative;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

.Frontpage .FrontpageHeading h1 {
  color: white;
  font-family: var(--title-font);
  font-weight: 800;
  font-size: 9rem;
  padding-left: 30px;
  padding-right: 30px;
}

.Frontpage .FrontpageHeading p {
  color: white;
  font-family: "Poppins", sans-serif, serif;
  font-weight: 600;
  font-size: 1.5rem;
  max-width: 850px;
  padding-left: 30px;
  padding-right: 30px;
}

.Frontpage .FrontpageHeading b {
  font-size: 2rem;
}

.Frontpage .FeaturedInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 800px;
}

.Frontpage .FeaturedInfo:first-of-type {
  margin-top: -150px;
}

.Frontpage .FeaturedInfo .InfoWords {
  height: 100%;
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;

  padding-left: 100px;
  padding-right: 60px;
}

.Frontpage .FeaturedInfo .InfoWords h1 {
  font-family: var(--title-font);
  font-size: 3rem;
  width: 300px;
  margin-top: -20px;
}

.Frontpage .FeaturedInfo .InfoWords p {
  font-family: var(--body-font);
  font-size: 1.25rem;
  font-weight: 800;
  color: var(--text-light);
}

.Frontpage .FeaturedInfo .InfoImage {
  flex-basis: 60%;
  height: fit-content;

  border-radius: 35px;

  overflow: hidden;

  /* border: 1px solid var(--content-light); */
  /* box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.08),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.08), 0px 6px 6px -3px rgba(0, 0, 0, 0.08),
    0px 12px 12px -6px rgba(0, 0, 0, 0.08),
    0px 24px 24px -12px rgba(0, 0, 0, 0.08); */
}

.Frontpage .FeaturedInfo .KeyHeader {
  border: 2px solid var(--post-plant-red);
  padding: 4px 20px;
  border-radius: 55px;
}

.Frontpage .InfoImage img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.Frontpage .FrontpageFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;

  gap: 40px;

  border-top: 1px solid var(--content);
  width: 100%;
  height: 300px;
  margin-top: 100px;
}

.Frontpage .FrontpageFooter .FooterLinks {
  display: flex;
  gap: 30px;
  margin-top: 18px;
}

.Frontpage .FrontpageFooter .FooterLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.Frontpage .FrontpageFooter .FooterLinks a {
  transition: color 150ms ease-out;
}

.Frontpage .FrontpageFooter .FooterLinks a:hover {
  color: var(--post-plant-red);
}

.Frontpage .FrontpageFooter .Logo {
  gap: 10px;
}

.Frontpage .FrontpageFooter .FooterCopywrite {
  display: flex;
  gap: 30px;
  margin-left: 20px;

  justify-content: center;
  align-items: center;
}
.Frontpage .FrontpageFooter .FooterCopywrite h4 {
  margin-left: -20px;
}

.Frontpage .FrontpageFooter .FooterTermsLinks {
  display: flex;
  gap: 20px;
}

.Frontpage .FrontpageFooter .FooterTermsLinks p {
  color: var(--text-light);
  transition: color 150ms ease-out;
}

.Frontpage .FrontpageFooter .FooterTermsLinks p:hover {
  color: white;
}

@media screen and (max-width: 867px) {
  .Frontpage .FrontpageHeading h1 {
    font-size: 6rem;
  }
  .Frontpage .FrontpageHeading p {
    font-size: 1rem;
  }

  .Frontpage .FeaturedInfo {
    flex-direction: column;
  }

  .Frontpage section {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 600px) {
  .Frontpage .FrontpageHeading h1 {
    font-size: 3rem;
  }
  .Frontpage .FrontpageHeading p {
    font-size: 0.8rem;
  }
}
