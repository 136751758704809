.ReplayPlayer {
  background-color: var(--content-light);
  height: fit-content;
  min-height: 56px;
  padding: 2px 0px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ReplayPlayer .Agent {
  padding-left: 5px;
}

.ReplayPlayer .PlayerName {
  margin-left: 10px;
  font-weight: 600;
  width: 80px;
}

.ReplayPlayer .PlayerEconomy {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 70px;
  margin-left: 10px;
}

.ReplayPlayer .PlayerEconomy p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
  letter-spacing: 1px;
  font-size: 0.8rem;
}

.ReplayPlayer .PlayerEconomy .Spent {
  margin-left: -9px;
  color: #bfb692;
}

.ReplayPlayer .PlayerEconomy .Spent img {
  color: #bfb692;
}

.ReplayPlayer .Arsenal {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-left: 10px;
  width: 100px;
  gap: 8px;
}

.ReplayPlayer .Arsenal .Weapon img {
  transform: scaleX(-1);
}

.Blue-outline {
  -webkit-filter: drop-shadow(4px 3px 0 #22ffc5)
    drop-shadow(-3px -3px 0 #22ffc5);
  filter: drop-shadow(4px 3px 0 #22ffc5) drop-shadow(-3px -3px 0 #22ffc5);
}

.Red-outline {
  -webkit-filter: drop-shadow(4px 3px 0 #ff4655)
    drop-shadow(-3px -3px 0 #ff4655);
  filter: drop-shadow(4px 3px 0 #ff4655) drop-shadow(-3px -3px 0 #ff4655);
}

.Yellow-outline {
  -webkit-filter: drop-shadow(4px 3px 0 #f0cb74)
    drop-shadow(-3px -3px 0 #f0cb74);
  filter: drop-shadow(4px 3px 0 #f0cb74) drop-shadow(-3px -3px 0 #f0cb74);
}
