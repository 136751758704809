.ScoreboardTab {
  font-size: 2rem;
  color: white;
  margin-top: 60px;
}

.ScoreboardTab .ScoreHeading {
  font-weight: 800;
  display: flex;
  gap: 20px;
  padding-left: 25px;
  margin-top: -10px;
  padding-bottom: 20px;
}

.ScoreHeading .Date {
  margin-top: 4px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
  font-size: 1rem;
  color: var(--text-light);
}

.ScoreboardTab .Teams {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  font-weight: 600;

  width: fit-content;
}

.ScoreboardTab .ScoreboardTeam:last-child .TeamName {
  margin-left: -4px;
}
