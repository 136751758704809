.MatchTabHeader {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: fit-content;
  position: absolute;
  z-index: 100;
  margin-top: 10px;
}

.MatchTabLabel {
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  transition: all 100ms ease-out;
}

.MatchTabLabel:hover {
  color: var(--post-plant-red);
}

.MatchTabHeader .TabActive h4 {
  border-bottom: 2px solid var(--post-plant-red);
  padding-bottom: 4px;
  color: var(--post-plant-red);
}
