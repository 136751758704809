.MatchPreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  height: 80px;
  border-radius: 15px;
  background-color: var(--content);
  border: 1px solid var(--content-light);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.02),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.02), 0px 6px 6px -3px rgba(0, 0, 0, 0.02),
    0px 12px 12px -6px rgba(0, 0, 0, 0.02),
    0px 24px 24px -12px rgba(0, 0, 0, 0.02);

  overflow: hidden;
  color: white;

  font-size: 1rem;

  transition: background-color 100ms ease-out;
  cursor: pointer;
}

.MatchPreview:hover {
  background-color: var(--content-light);
}

.MatchPreview .ItemContainer:not(:first-child):not(:nth-child(2)) {
  margin-right: auto;
  margin-left: 15px;
}

.MatchPreview .ItemContainer:nth-child(3) {
  margin-left: 0px !important;
}

.MatchPreview .WinMarker {
  height: 100%;
  width: 6px;
  z-index: 11;
  min-width: 6px;
}

.MatchPreview .Map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  flex-basis: auto;
  width: 228px;
}

.MatchPreview .Map .MapSplash {
  /* mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 20%, transparent 85%); */
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 10%,
    rgba(255, 255, 255, 0.8),
    rgba(0, 0, 0, 0) 95%
  );
}

.MatchPreview .Map h1 {
  position: absolute;
  color: white;
}

.MatchPreview .Map .AgentIcon {
  position: absolute;
  z-index: 15;
  left: 0px;
}

.MatchPreview .Score {
  min-width: 60px;
  text-align: center;
}
