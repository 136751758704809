.Trades {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;

  width: 100%;
  height: 100%;
}

.Trades .TradeStat {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Trades .TradeStat .TradeWord {
  width: 250px;
}

.Trades .TradeStat p {
  font-family: var(--body-font);
}

.Trades .TradeStat h2 {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
