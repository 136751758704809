.SelectVOD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  gap: 100px;
}

.SelectVOD .VODsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
