.BestAgent {
  display: flex;
  height: 100%;

  justify-content: center;
  align-items: center;
}

.BestAgent .AgentImage {
  text-align: center;
}

.BestAgent .AgentImage h4 {
  font-weight: 800;
  color: var(--text-light);
  font-family: "Poppins";
  font-size: 1rem;
  margin-left: 2px;
}

.BestAgent .AgentStats {
  list-style: none;
  color: var(--text);
  display: flex;
  flex-direction: column;
  gap: 50px;
  font-size: 1.2em;
  font-weight: 800;
  padding-left: 15px;

  justify-content: center;
}

.BestAgent .AgentStats .StatsScore {
  font-family: var(--title-font);
  font-weight: 800;
  font-size: 1.2rem;
}

.BestAgent .AgentStats .Stat {
  display: flex;
  flex-direction: column;
}
