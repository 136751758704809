.ScoreboardPlayer {
  height: 50px;

  width: fit-content;
  min-width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  font-size: 1.2rem;
}

.ScoreboardPlayer .Player {
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 10px;
  min-width: 220px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
}

.ScoreboardPlayer:nth-child(odd) {
  background-color: var(--content-light);
}

.ScoreboardPlayer .Rank {
  margin-left: -10px;
}

.ScoreboardPlayer > div {
  text-align: center;
}

.ScoreboardPlayer > div:nth-child(2) {
  width: 30px;
}

.ScoreboardPlayer > div:nth-child(3) {
  margin-left: 15px;
  min-width: 50px;
  max-width: 50px;
}

.ScoreboardPlayer > div:nth-child(4) {
  min-width: 30px;
  max-width: 30px;
  margin-left: 6px;
}

.ScoreboardPlayer > div:nth-child(5) {
  min-width: 30px;
  max-width: 30px;
  margin-left: -15px;
}

.ScoreboardPlayer > div:nth-child(6) {
  min-width: 30px;
  max-width: 30px;
  margin-left: -15px;
}

.ScoreboardPlayer > div:nth-child(7) {
  min-width: 40px;
  max-width: 40px;
  margin-left: 5px;
}

.ScoreboardPlayer > div:nth-child(8) {
  min-width: 45px;
  max-width: 45px;
  margin-left: 8px;
}

.ScoreboardPlayer > div:nth-child(9) {
  min-width: 60px;
  max-width: 60px;
  margin-left: 4px;
}

.ScoreboardPlayer > div:nth-child(10) {
  min-width: 60px;
  max-width: 60px;
}

.ScoreboardPlayer > div:nth-child(11) {
  min-width: 35px;
  max-width: 35px;
  margin-left: 4px;
}

.ScoreboardPlayer > div:nth-child(12) {
  min-width: 35px;
  max-width: 35px;
}

.ScoreboardPlayer > div:nth-child(13) {
  min-width: 35px;
  max-width: 35px;
  margin-left: 5px;
}

.ScoreboardPlayer > div:nth-child(14) {
  min-width: 35px;
  max-width: 35px;
  margin-left: 4px;
}

.ScoreboardPlayer > div:nth-child(15) {
  min-width: 80px;
  max-width: 60px;
  white-space: nowrap;
  font-size: 1rem;
}

.ScoreboardPlayer > div:nth-child(16) {
  min-width: 45px;
  max-width: 45px;
  margin-left: 2px;
}
