.Scrubber {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.Scrubber .Events {
  height: 100%;
  width: 100%;
  position: absolute;

  pointer-events: none;
}

.Scrubber .Events .Kills {
  height: 100%;
  width: 100%;

  position: relative;
  pointer-events: none;
}

.Scrubber .Events .Kill {
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: white;
}

.Scrubber .Events .Plant {
  width: 100px;
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: var(--post-plant-red);
  display: flex;
  align-items: center;
}

.Scrubber .Events .PlantBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--post-plant-red);
  width: 20px;
  height: 20px;
  margin-left: -8px;
}

.Scrubber .Events .PlantBox h4 {
  margin-top: -1px;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 880px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    background-color: var(--main-blue);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 4rem;
    color: #414a73;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 3rem;
    width: 0.1rem;
    cursor: ew-resize;
    background: #394266;
    box-shadow: -1000px 0 0 1000px #394266;
  }
}
