.Performance .MainContent {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;
  align-content: flex-start;
  margin-top: 80px;

  /* border: 1px solid purple; */
}

.Performance .ContentBox {
  background-color: var(--content);
  border-radius: var(--content-box-radius);
  border: 1px solid var(--content-light);

  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.08),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.08), 0px 6px 6px -3px rgba(0, 0, 0, 0.08),
    0px 12px 12px -6px rgba(0, 0, 0, 0.08),
    0px 24px 24px -12px rgba(0, 0, 0, 0.08);

  height: 100%;
  padding: 15px;
}

.Performance .TopContent {
  display: flex;
  gap: 20px;
  height: 350px;
}

.Performance .MiddleContent {
  display: flex;
  gap: 20px;
  height: 390px;
}

.Performance .BottomContent {
  display: flex;
  gap: 20px;
  height: 450px;
}

.Performance .Link .ContentBox {
  transition: transform 0.3s ease;
}

.Performance .Link:hover .ContentBox {
  transform: translateY(-10px);
  background-color: var(--content-light);
}

.Performance .RankHistory {
  margin-top: -10px;
}
