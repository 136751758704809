.Page {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.Page .PageContent {
  width: 100%;
  height: 100%;
  padding: 25px;
  padding-left: 0px;
  overflow-y: auto;
}
