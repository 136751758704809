.ReplayTeams {
  width: 400px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ReplayTeams .ReplayTeam .Players {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ReplayTeams .ReplayTeam .Heading {
  height: 40px;
  font-size: 2rem;
  background-color: var(--content);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px 10px 0px 0px;

  white-space: nowrap;
  gap: 10px;
}
