.Multikills {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.Multikills .MultikillContainer {
  display: flex;
  text-align: center;
  justify-content: center;
}

.Multikills .MultikillTitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-light);
  background-color: var(--content) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.Multikills .PerRoundMulti {
  width: 80px;
}

.Multikills .TypeMulti {
  width: 80px;
}

.Multikills .TotalMulti {
  width: 80px;
}

.Multikills .TypeMulti h2 {
  background-color: var(--content-light);
}

.Multikills h2 {
  border-bottom: 1px solid var(--content-light);
  padding-top: 10px;
  padding-bottom: 10px;
}
