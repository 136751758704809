.VideoContainer {
  width: fit-content;
  position: relative;
  margin-top: -270px;
}

.VideoContainer .Blocker {
  position: absolute;
  background-color: var(--background);
}

.VideoContainer .Header {
  top: 0;
  z-index: 1;
}

.VideoContainer .Footer {
  bottom: 4px;
}

.VideoContainer .ControlsContainer {
  position: absolute;
  z-index: 200;
  margin-top: -273px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 1000px;
}

.VideoContainer .VideoYoutube .PageTitle {
  position: absolute;
  top: 126px;
  z-index: 3;
}
