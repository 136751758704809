.Sidebar {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  height: 94%;
  min-height: 638px;

  background-color: var(--background-dark);
  font-family: var(--title-font);

  margin: 25px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.06),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.06), 0px 6px 6px -3px rgba(0, 0, 0, 0.06),
    0px 12px 12px -6px rgba(0, 0, 0, 0.06),
    0px 24px 24px -12px rgba(0, 0, 0, 0.06);
}

.Sidebar hr {
  margin-top: -10px;
  margin-bottom: 10px;
  width: 55%;
  border: 1px solid var(--background);
  border-bottom: none;
}

.Sidebar .PageLinks {
  width: 100%;
}

.Sidebar .PageLinks ul {
  list-style: none;
}

.Sidebar .PageLinks .SettingsOption {
  position: absolute;
  bottom: 0;
  margin-bottom: 70px;
  width: 100%;
}

.Sidebar .PageLinks .Logout {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
}

.Sidebar .Profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Sidebar .Account {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 6px;
}

.Sidebar .Profile .Rank {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.Sidebar .Profile .Rank .color {
  color: var(--post-plant-red);
}

.Sidebar .Profile .Rank .size {
  font-size: 0.8rem;
}

.Sidebar .Profile h4 {
  color: var(--text-secondary);
}

.Sidebar .Profile .Account span {
  font-size: 0.8rem;
  color: var(--text-secondary);
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
}

.Sidebar .Profile .AccLevel {
  position: relative;
  text-align: center;
}

.Sidebar .Profile .AccLevel h4 {
  font-weight: 600;
  font-size: 0.8rem;
  color: #eae6e4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  z-index: 100;
}
