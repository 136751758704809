.ClutchesPerformance {
  display: flex;
  flex-direction: row;
  height: 94%;
  justify-content: center;
  align-items: flex-start;
}

.ClutchesPerformance .DonutContainer {
  width: 200px;
  height: fit-content;
  position: relative;
}

.ClutchesPerformance .MainStats {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  text-align: center;

  margin-right: 40px;
  margin-left: 20px;
}

.ClutchesPerformance .MainStats h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 1.5rem;
}

.ClutchesPerformance .ClutchStat {
  width: 100%;
}

.ClutchesPerformance .ClutchStat h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  width: 280px;
}

.ClutchesPerformance .ClutchStat p {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-light);
}

.ClutchesPerformance .BarContainer {
  height: 100%;
  width: 100%;
}
