.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: var(--body-font);
  background-color: var(--background);
}

.signup-container .signup-form {
  border-radius: var(--content-box-radius);
  background-color: var(--content);
  border: 1px solid var(--content-light);

  width: 400px;
  height: 400px;
  padding: 20px;
  color: white;
  box-shadow: 0px 0px 10px 1px var(--background-dark);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.signup-container .signup-title {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.signup-container hr {
  width: 75%;
  border-bottom: none;
  border-top: 1px solid white;
  margin-bottom: 10px;
}

.signup-container .riot-btn {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: #d13639;
  color: white;
  padding: 0px 45px;
  border: none;
  border-radius: 4px;
  font-size: 16px;

  cursor: pointer;
  font-family: "Poppins";
  transition: background-color 100ms ease-out;
}

.signup-container .riot-btn:hover {
  background-color: var(--post-plant-red);
}

.signup-container .riot-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.signup-container .form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  width: 100%;

  text-align: center;
}

.signup-container h1 {
  font-size: 2rem;
  font-weight: 800;
}

.signup-container h3 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.signup-container .form-container input {
  width: 78%;
  height: 30px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--main-blue);
  color: white;
  border: 1px solid darkgray;
  font-family: "Poppins";
  padding-left: 10px;
}

.signup-container .form-container input:focus {
  border: 1px solid white;
}

.signup-container .form-container input::placeholder {
  color: darkgray;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.signup-container .signup-btn:disabled {
  background-color: #ccc; /* Change the background color when disabled */
  cursor: not-allowed; /* Change the cursor when disabled */
  color: black;
}

.signup-container a:link {
  text-decoration: none;
  color: #d13639;
  transition: color 100ms ease-out;
}

.signup-container a:visited {
  text-decoration: none;
  color: #d13639;
}

.signup-container a:hover {
  color: var(--post-plant-red);
}

.signup-container p {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.signup-container .bottomSignup {
  margin-bottom: 10px;
  margin-top: 40px;
  width: 250px;
}

.signup-container .checkbox-container {
  width: 100%;
  display: flex;
  align-items: center;

  font-size: 0.7rem;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-top: 10px;
}
.signup-container .checkbox-container label {
  margin-left: -50px;
  margin-top: -12px;
}

.signup-container .checkbox-container label:hover {
  cursor: pointer;
}

.signup-container .checkbox-container input {
  margin-left: -25px;
  cursor: pointer;
}

.signup-container .signup-link-wrapper:link {
  text-decoration: none;
  color: var(--text);
}

.signup-container .signup-link-wrapper:visited {
  text-decoration: none;
  color: var(--text);
}

.signup-container .signup-link-wrapper.active {
  color: var(--text);
}
