.Rounds {
  margin-top: 5px;
  height: 55px;
  display: flex;
  gap: 5px;
}

.Round {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 10px;
  border: 1px solid var(--text-light);

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.Round:hover {
  background-color: var(--content-light);
  cursor: pointer;
}
