.WinratePerf {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.WinratePerf h1 {
  font-size: 2rem;
  font-weight: 600;
}

.WinratePerf h2 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-light);
}

.WinratePerf hr {
  width: 55%;
  border: 1px solid var(--content-light);
  border-bottom: none;
}

.WinratePerf .WinrateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-family: var(--body-font);
  width: 120%;
}

.WinratePerf .WinrateBar {
  background-color: var(--content-light);
  width: 150px;
  height: 15px;
  border-radius: 5px;
}

.WinratePerf .WinrateBar .WinratePercentage {
  background-color: var(--post-plant-red);
  height: 15px;
  border-radius: 5px;
  border-radius: 5px 0px 0px 5px;
}
