.PageTitle {
  margin-top: 25px;
  width: 100%;
}

.PageTitle h1 {
  font-size: 2rem;
  font-weight: 800;
}

.PageTitle p {
  font-family: var(--body-font);
  color: var(--text-light);

  font-size: 0.9rem;
}
