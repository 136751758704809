.VideoControls {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: 100%;
  width: 140px;
}

.VideoControls button {
  border: none;
  color: white;
  background-color: var(--background);
  cursor: pointer;
}

.VideoControls button:hover {
  background-color: var(--content-light);
}
