.MatchesContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;

  width: 100%;
  height: 100%;

  list-style: none;
}

.MatchesContent .MatchItemLink {
  text-decoration: none;
}

.MatchesContent .MatchesLegend {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.MatchesContent .MatchesLegend .LegendKey {
  margin-right: auto;
  margin-left: 15px;
  flex: 1;
}

.MatchesContent .MatchesLegend .LegendKey:nth-child(1) {
  min-width: 85px;
  max-width: 85px;
}

.MatchesContent .MatchesLegend .LegendKey:nth-child(2) {
  min-width: 135px;
  max-width: 135px;
}

.MatchesContent .MatchesLegend .LegendKey:nth-child(3) {
  margin-right: 40px;
}

.MatchesContent .MatchesLegend .LegendKey:nth-child(4) {
  margin-right: 20px;
}
.MatchesContent .MatchesLegend .LegendKey:nth-child(5) {
  margin-right: 25px;
}
