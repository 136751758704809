.MatchItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  height: 50px;
  border-radius: 15px;
  background-color: var(--content);

  overflow: hidden;
  color: white;

  font-size: 1rem;

  transition: background-color 100ms ease-out;
  cursor: pointer;
}

.MatchItem:hover {
  background-color: var(--content-light);
}

.MatchItem .ItemContainer:not(:first-child):not(:nth-child(2)) {
  margin-right: auto;
  margin-left: 15px;
}

.MatchItem .ItemContainer:nth-child(3) {
  margin-left: 0px !important;
}

.MatchItem .ItemContainer:nth-child(4) {
  margin-left: 28px !important;
}

.MatchItem .ItemContainer:nth-child(5) {
  margin-left: 20px !important;
}

.MatchItem .ItemContainer:nth-child(6) {
  margin-left: 40px !important;
}

.MatchItem .WinMarker {
  height: 100%;
  width: 6px;
  z-index: 11;
  min-width: 6px;
}

.MatchItem .Map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  flex-basis: auto;
  width: 228px;
}

.MatchItem .Map .MapSplash {
  /* mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 20%, transparent 85%); */
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 10%,
    rgba(255, 255, 255, 0.8),
    rgba(0, 0, 0, 0) 95%
  );
}

.MatchItem .Map h1 {
  position: absolute;
  color: white;
}

.MatchItem .Map .AgentIcon {
  position: absolute;
  z-index: 15;
  left: 0px;
}

.MatchItem .Score {
  min-width: 60px;
  text-align: center;
}
