.Settings .PageContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
}

.Settings .MainContent {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;
  align-content: flex-start;

  padding-left: 20%;
  padding-right: 20%;
}

.Settings .ContentBox {
  background-color: var(--content);
  border-radius: var(--content-box-radius);
  border: 1px solid var(--content-light);

  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.08),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.08), 0px 6px 6px -3px rgba(0, 0, 0, 0.08),
    0px 12px 12px -6px rgba(0, 0, 0, 0.08),
    0px 24px 24px -12px rgba(0, 0, 0, 0.08);

  padding: 15px;
  padding-top: 40px;
  min-height: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.Settings .AccountName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 6px;
  font-size: 1.5rem;
}

.Settings .AccountName span {
  font-size: 1.2rem;
  color: var(--text-secondary);
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
}
