.BestMap {
  display: flex;
  flex-direction: column;
}

.BestMap .MapImage {
  position: relative;
  margin-top: 20px;
  z-index: 1;

  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 30%, transparent 100%);
}

.BestMap .MapImage h1 {
  position: absolute;
  font-size: 2rem;
  right: 33%;
  bottom: 0px;
  color: var(--text);
  background-color: var(--content);
  width: fit-content;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-radius: 5px;
  z-index: 100;
}

.BestMap .MapStats {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  margin-top: 50px;
  font-family: var(--title-font);
  font-weight: 800;
  font-size: 1.2rem;
}
.BestMap .MapStats .Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
