.Timeline {
  margin-top: 10px;
  height: 55px;
  /* border: 1px solid var(--text-light); */
  border-radius: 10px;

  overflow: hidden;
  display: flex;
  align-items: center;
}
